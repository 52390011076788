import { useCallback, useMemo } from 'react'
import {
  CollectiveMembersQueryResult,
  CollectiveWalletType,
  useCollectiveAssetsQuery,
  useCollectiveMembersQuery,
  ERCAsset
} from 'graphql/generated'

export type MemberType = NonNullable<
  CollectiveMembersQueryResult['collective']
>['members']['edges'][0]['node']

interface IProps {
  collectiveId: string
  address: Maybe<string>
  tokenId?: Maybe<string>
  skip?: boolean
}

const useProposalCommandPreview = ({
  collectiveId,
  address,
  tokenId,
  skip = true
}: IProps) => {
  const { data: assetsData } = useCollectiveAssetsQuery({
    variables: {
      collectiveId: collectiveId,
      assetsConnection: {
        first: 1
      },
      where: {
        collectiveWalletType: CollectiveWalletType.COLLECTIVE,
        searchString: `${address} ${tokenId}`
      }
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip
  })

  const asset = useMemo(
    () =>
      assetsData?.collective?.assets.edges
        .filter(
          edge =>
            edge.node.__typename === 'ERCAsset' &&
            (!tokenId || edge.node.tokenId === tokenId)
        )
        .map(edge => edge.node)[0] as Maybe<ERCAsset>,
    [assetsData, tokenId]
  )

  const { data: membersData } = useCollectiveMembersQuery({
    variables: {
      collectiveId: collectiveId,
      memberConnection: {
        first: 5000
      }
    },
    skip,
    fetchPolicy: 'cache-first'
  })

  const findMemberByAddress = useCallback(
    (address: Maybe<string>) =>
      membersData?.collective?.members.edges.find(
        edge => edge.node.user.address.toLowerCase() === address
      )?.node,
    [membersData]
  )

  return {
    asset,
    findMemberByAddress
  }
}

export default useProposalCommandPreview
