import {
  COMMAND_OPTIONS,
  TCommandComponent
} from 'components/Collectives/modals/AddProposalModal/AddProposal/ProposalCommand.types'
import { useCollectiveDemoContext } from 'context/CollectiveDemoContext'
import {
  CollectiveProposalCommand,
  CollectiveProposalType,
  ProposalCommandInput,
  useCreateProposalMutation
} from 'graphql/generated'
import { useQueryErrorHandler } from 'hooks'
import { useAddProposal } from 'hooks/collectives/demo/useAddProposal'
import { without } from 'lodash'
import { useRouter } from 'next/router'
import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useCallback,
  useState
} from 'react'

type DoCreateProposalProps = {
  title: string
  durationInHours: number
  description: string
  collectiveId: string
}

interface CreateProposalContextShape {
  saving: boolean
  demoMode: Maybe<boolean>
  setDescription: React.Dispatch<React.SetStateAction<string>>
  description: string
  setTitle: React.Dispatch<React.SetStateAction<string>>
  title: string
  setDurationInHours: React.Dispatch<React.SetStateAction<number>>
  durationInHours: number
  setComponent: React.Dispatch<React.SetStateAction<Maybe<TCommandComponent>>>
  component: Maybe<TCommandComponent>
  doCreateProposal: CallbackWithParam<DoCreateProposalProps>
  commands: ProposalCommandInput[]
  setCommands: React.Dispatch<React.SetStateAction<ProposalCommandInput[]>>
  currentStep: number
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  goForward: (resetComponent: boolean) => void
  goBack: (resetComponent: boolean) => void
  updateStep: (step: number) => void
}

interface CreateProposalContextProps {
  children?: React.ReactNode
  commands?: ProposalCommandInput[]
  onClose?: Callback
  demoMode: Maybe<boolean>
}
const CreateProposalContext = createContext<CreateProposalContextShape>(
  {} as CreateProposalContextShape
)

export const useCreateProposalContext = () => useContext(CreateProposalContext)

export function CreateProposalProvider({
  children,
  commands: initValues,
  demoMode,
  onClose
}: PropsWithChildren<CreateProposalContextProps>) {
  const addProposals = useAddProposal()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [durationInHours, setDurationInHours] = useState(24)

  const { setViewingProposal } = useCollectiveDemoContext()
  const [initType] = useState(initValues?.[0].type)
  const [commands, setCommands] = useState<ProposalCommandInput[]>(
    initValues ? without(initValues, initValues[initValues.length - 1]) : []
  )
  const [component, setComponent] = useState<TCommandComponent | undefined>(
    initType
      ? COMMAND_OPTIONS.find(option => option.renderForTypes.includes(initType))
          ?.component
      : undefined
  )
  const [currentStep, setCurrentStep] = useState(initValues ? 1 : 0)

  const { push } = useRouter()
  const onError = useQueryErrorHandler(`Failed to create proposal`)
  const [createProposal, { loading }] = useCreateProposalMutation({
    onError: err => {
      onError(err)
      window.analytics?.trackAll(`Proposal - Create Proposal - Error`, {
        proposalName: title,
        commandTypes: commands.map(x => x.type).join(' + ')
      })
    },
    onCompleted: resp => {
      window.analytics?.trackAll(`Proposal - Create Proposal - Complete`, {
        proposalId: resp.createProposal?.id,
        proposalName: title,
        commandTypes: commands.map(x => x.type).join(' + ')
      })
    }
  })

  const doCreateProposal = useCallback(
    async ({
      title,
      durationInHours,
      description,
      collectiveId
    }: DoCreateProposalProps) => {
      if (demoMode) {
        const proposal = addProposals({
          commands: [
            {
              __typename: 'CollectiveProposalAddMemberCommand',
              memberAddress: commands[0].memberAddress
            }
          ] as CollectiveProposalCommand[],
          title: title,
          description: description,
          durationHours: durationInHours
        })
        setViewingProposal(proposal)

        onClose?.()
        return
      }

      const resp = await createProposal({
        variables: {
          collectiveId: collectiveId,
          proposal: {
            type: CollectiveProposalType.COMMANDS,
            title,
            durationInHours,
            description,
            commands: commands || []
          }
        }
      })

      const prop = resp.data?.createProposal
      if (!prop) {
        throw new Error(`Failed to create proposal`)
      }

      window.analytics.trackAll('Created Proposal', {
        proposalId: prop.id,
        commands: prop.commands.map(x => x.__typename)
      })

      push(prop.publicUrl)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      addProposals,
      commands,
      createProposal,
      demoMode,
      onClose,
      push,
      setViewingProposal
    ]
  )

  const goForward = useCallback(
    (resetComponent: boolean) => {
      if (resetComponent) {
        setComponent(undefined)
      }

      setCurrentStep(currentStep + 1)
    },
    [currentStep]
  )

  const goBack = useCallback(
    (resetComponent: boolean) => {
      if (resetComponent) {
        setComponent(undefined)
      }

      if (!commands.length && currentStep === 0) {
        onClose?.()
      }

      setCurrentStep(currentStep - 1)
    },
    [currentStep, onClose, commands]
  )

  const updateStep = useCallback((step: number) => {
    setComponent(undefined)
    setCurrentStep(step)
  }, [])

  return (
    <CreateProposalContext.Provider
      value={{
        commands,
        setCommands,
        component,
        setComponent,
        goForward,
        goBack,
        updateStep,
        currentStep,
        setCurrentStep,
        doCreateProposal,
        saving: loading,
        title,
        setTitle,
        description,
        setDescription,
        durationInHours,
        setDurationInHours,
        demoMode
      }}
    >
      {children}
    </CreateProposalContext.Provider>
  )
}
