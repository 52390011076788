import Thumbnail from 'components/Thumbnail'
import { CollectiveSignatorsQueryResult } from 'graphql/generated'
import { components, OptionProps } from 'react-select'
import EtherscanLink from '../EtherscanLink'
import Typography from 'components/common/Typography'
import React from 'react'
import { useCollectiveContext } from 'context/CollectiveContext'

export type TCollectiveUser = NonNullable<
  CollectiveSignatorsQueryResult['collective']
>['signators'][0]['user']

export const CollectiveUserSelectSingleValueShell: React.FC<{
  user: TCollectiveUser
}> = ({ user }) => {
  const { collective } = useCollectiveContext()

  return (
    <div className="flex flex-row items-center">
      <div className="pr-3">
        <Thumbnail
          src={user.platformUser?.thumb}
          className="h-8 w-8 rounded-full overflow-hidden"
        />
      </div>

      <div className="flex flex-col justify-center">
        <Typography size="sm">{user.platformUser?.displayName}</Typography>
        <EtherscanLink
          network={collective.network}
          type={'address'}
          address={user.address}
          shortAddress
        />
      </div>
    </div>
  )
}

const CollectiveUserSelectSingleValue = ({
  ...rest
}: OptionProps<TCollectiveUser, false, any>) => {
  const user = rest.data as TCollectiveUser

  return (
    <components.SingleValue {...rest}>
      <CollectiveUserSelectSingleValueShell user={user} />
    </components.SingleValue>
  )
}

export default CollectiveUserSelectSingleValue
