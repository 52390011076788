import React from 'react'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { ProposalCommandInput, ProposalCommandType } from 'graphql/generated'
import { TCollective } from 'components/Collectives/modals/AddProposalModal/AddProposal/ProposalCommand.types'
import Typography from 'components/common/Typography'
import EtherscanLink from 'components/Collectives/Common/EtherscanLink'
import { formatToken } from 'lib/collectives/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useProposalCommandPreview from 'hooks/collectives/useProposalCommandPreview'
import { UserPreview, AssetPreview } from './common'
import { useCreateProposalContext } from './CreateProposalContext'
import { AnonymityKeyMap } from 'components/Collectives/modals/AddProposalModal/AddProposal/ChangeVotingAnonymityCommand'
import { twMerge } from 'tailwind-merge'

type ProposalCommandPreviewProps = {
  commandLabel: string
  collective: TCollective
  command: ProposalCommandInput
  limitTexts: boolean
  index: number
}

type SummaryPart = {
  label: string
  details: React.ReactNode | string
  detailsClassName?: string
  className?: string
}
interface ICommandSummaryBox {
  commandLabel: string
  parts?: Maybe<SummaryPart[]>
  index: number
}

const loadAssetInfoForCommandTypes = [
  ProposalCommandType.TRANSFER_TOKENS,
  ProposalCommandType.TRANSFER_ERC1155,
  ProposalCommandType.TRANSFER_ERC721,
  ProposalCommandType.TRANSFER_NFT
]

const ProposalCommandPreview: React.FC<ProposalCommandPreviewProps> = ({
  collective,
  command,
  commandLabel,
  index
}) => {
  const { asset, findMemberByAddress } = useProposalCommandPreview({
    collectiveId: collective.id,
    address: command.contractAddress,
    tokenId: command.tokenId,
    skip: !loadAssetInfoForCommandTypes.includes(command.type)
  })

  switch (command.type) {
    case ProposalCommandType.ADD_MEMBER:
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'Member Address',
              details: (
                <EtherscanLink
                  inline
                  network={collective.network}
                  type={'address'}
                  address={command.memberAddress!}
                  shortAddress
                />
              )
            }
          ]}
        />
      )
    case ProposalCommandType.REMOVE_MEMBER: {
      const user = findMemberByAddress(command.memberAddress!)

      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'MEMBER',
              details: (
                <UserPreview
                  address={command.memberAddress!}
                  user={user}
                  collective={collective}
                />
              )
            }
          ]}
        />
      )
    }

    case ProposalCommandType.REMOVE_ZERO_BALANCE_MEMBERS: {
      return <CommandSummaryBox index={index} commandLabel={commandLabel} />
    }
    case ProposalCommandType.CHANGE_ENABLE_NEW_MEMBERSHIP_REQUESTS:
      return <CommandSummaryBox index={index} commandLabel={commandLabel} />
    case ProposalCommandType.CHANGE_ENABLE_WITHDRAWS:
      return <CommandSummaryBox index={index} commandLabel={commandLabel} />
    case ProposalCommandType.CHANGE_ENABLE_DEPOSITS:
      return <CommandSummaryBox index={index} commandLabel={commandLabel} />
    case ProposalCommandType.CHANGE_GATE_DEPOSITS:
      return <CommandSummaryBox index={index} commandLabel={commandLabel} />
    case ProposalCommandType.CHANGE_EXCHANGE_RATE:
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={`Set exchange rate to: ${command.exchangeRate} ${collective.tokens[0].symbol} = 1 Ether`}
        />
      )
    case ProposalCommandType.SEND_ETHER: {
      const user = findMemberByAddress(command.target!)

      return (
        <CommandSummaryBox
          index={index}
          commandLabel={`Send Ether`}
          parts={[
            {
              label: 'AMNT',
              details: `${formatToken(command.value!)} ETH`
            },
            {
              label: 'TO',
              details: (
                <UserPreview
                  address={command.target!}
                  user={user}
                  collective={collective}
                />
              )
            }
          ]}
        />
      )
    }
    case ProposalCommandType.SEND_DISBURSEMENT:
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={`Disburse ${formatToken(command.value!)} Ether to ${
            collective.totalMembers
          } members`}
        />
      )
    case ProposalCommandType.SEND_TOKEN: {
      const contractDetails = collective.tokens[0]
      const user = findMemberByAddress(command.target)

      return (
        <CommandSummaryBox
          index={index}
          commandLabel={`Mint tokens`}
          parts={[
            {
              label: 'AMNT',
              details: (
                <EtherscanLink
                  inline
                  network={collective.network}
                  type={'address'}
                  address={command.contractAddress!}
                  label={`${formatToken(command.value!)} ${
                    contractDetails ? contractDetails.symbol : 'tokens'
                  }`}
                  shortAddress
                />
              )
            },
            {
              label: 'TO',
              details: (
                <UserPreview
                  address={command.target!}
                  user={user}
                  collective={collective}
                />
              )
            }
          ]}
        />
      )
    }
    case ProposalCommandType.TRANSFER_TOKENS: {
      const user = findMemberByAddress(command.target)

      return (
        <CommandSummaryBox
          index={index}
          commandLabel={`Transfer tokens `}
          parts={[
            {
              label: 'AMNT',
              details: (
                <EtherscanLink
                  inline
                  network={collective.network}
                  type={'address'}
                  address={command.contractAddress!}
                  label={`${formatToken(command.value!)} ${
                    asset ? asset.symbol : 'tokens'
                  }s`}
                  shortAddress={true}
                />
              )
            },
            {
              label: 'TO',
              details: (
                <UserPreview
                  address={command.target!}
                  user={user}
                  collective={collective}
                />
              )
            }
          ]}
        />
      )
    }
    case ProposalCommandType.TRANSFER_ERC1155:
    case ProposalCommandType.TRANSFER_ERC721:
    case ProposalCommandType.TRANSFER_NFT: {
      const user = findMemberByAddress(command.target)

      return (
        <CommandSummaryBox
          index={index}
          commandLabel={`Transfer Asset`}
          parts={[
            {
              label: 'ASSET',
              details: (
                <AssetPreview
                  contractAddress={command.contractAddress!}
                  asset={asset}
                  collective={collective}
                />
              )
            },
            {
              label: 'TO',
              details: (
                <UserPreview
                  address={command.target!}
                  user={user}
                  collective={collective}
                />
              )
            }
          ]}
        />
      )
    }
    case ProposalCommandType.ADD_SIGNATOR: {
      const user = findMemberByAddress(command.signatorAddress!)

      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'MEMBER',
              details: (
                <UserPreview
                  address={command.signatorAddress!}
                  user={user}
                  collective={collective}
                />
              )
            }
          ]}
        />
      )
    }
    case ProposalCommandType.REMOVE_SIGNATOR: {
      const user = findMemberByAddress(command.signatorAddress!)

      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'MEMBER',
              details: (
                <UserPreview
                  address={command.signatorAddress!}
                  user={user}
                  collective={collective}
                />
              )
            }
          ]}
        />
      )
    }
    case ProposalCommandType.CHANGE_SAFE_THRESHOLD:
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'AMNT',
              details: `${command.safeThreshold} will be required`
            }
          ]}
        />
      )
    case ProposalCommandType.CHANGE_QUORUM:
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'AMNT',
              details: `${command.quorumNeededInPercentage}% will be required`
            }
          ]}
        />
      )
    case ProposalCommandType.CHANGE_MIN_ETH_CONTRIBUTION:
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'AMNT',
              details: `${formatToken(
                command.minEthContribution!
              )} ETH will now be required`
            }
          ]}
        />
      )
    case ProposalCommandType.CALL_REMOTE:
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'TARGET',
              details: (
                <EtherscanLink address={command.target || ''} shortAddress />
              )
            },
            ...(command.value !== '0'
              ? [{ label: 'VALUE', details: command.value }]
              : []),
            {
              label: 'ABI DATA',
              details: (
                <Typography size="sm" className="min-w-[400px] break-all">
                  {command.data}
                </Typography>
              )
            }
          ]}
        />
      )
    case ProposalCommandType.LINK_WALLET:
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'ADDRESS',
              details: (
                <EtherscanLink
                  inline
                  network={collective.network}
                  type={'address'}
                  label={command.walletNickname!}
                  address={command.target!}
                  shortAddress
                />
              )
            }
          ]}
        />
      )

    case ProposalCommandType.UNLINK_WALLET:
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'ADDRESS',
              details: (
                <EtherscanLink
                  inline
                  network={collective.network}
                  type={'address'}
                  label={command.walletNickname!}
                  address={command.walletId!}
                  shortAddress
                />
              )
            }
          ]}
        />
      )

    case ProposalCommandType.CHANGE_REQUIRE_VOTE_SIGNATURE:
      return <CommandSummaryBox index={index} commandLabel={commandLabel} />

    case ProposalCommandType.CHANGE_VOTING_ANONYMITY:
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'Voting Privacy',
              details: (
                <p className="text-sm text-gray-800 line-clamp-none font-bold mt-2">
                  {command.votingAnonymity
                    ? AnonymityKeyMap[command.votingAnonymity]
                    : '(unknown)'}
                </p>
              )
            }
          ]}
        />
      )

    case ProposalCommandType.CHANGE_DISABLE_TOKEN_TRANSFERS:
      return <CommandSummaryBox index={index} commandLabel={commandLabel} />

    default:
      return null
  }
}

export const CommandSummaryBox: React.FC<ICommandSummaryBox> = ({
  commandLabel,
  parts,
  index
}) => {
  const { setCommands, commands } = useCreateProposalContext()

  return (
    <div className="w-full flex rounded-lg mb-4 bg-gray-100 justify-between">
      <div className="shrink-0 p-4">
        <Typography color="lightGray" fontWeight="light" size="xs">
          CMD
        </Typography>
        <Typography className="mt-2" size="sm">
          {commandLabel}
        </Typography>
      </div>

      {!!parts?.length && (
        <div className="flex-1 overflow-auto flex border-l border-gray-300">
          {parts.map((part, index) => {
            return (
              <div
                key={part.label + index}
                className={twMerge(
                  'px-3.5 p-4',
                  index > 0 && 'border-l border-gray-300'
                )}
              >
                <Typography color="lightGray" fontWeight="light" size="xs">
                  {part.label}
                </Typography>
                {typeof part.details === 'string' ? (
                  <Typography className="mt-2" size="sm">
                    {part.details}
                  </Typography>
                ) : (
                  <div className="mt-2">{part.details}</div>
                )}
              </div>
            )
          })}
        </div>
      )}

      <div
        className="my-auto px-4"
        onClick={() => {
          const updatedList = [...commands]
          updatedList.splice(index, 1)
          setCommands(updatedList)
        }}
      >
        <FontAwesomeIcon
          className="cursor-pointer"
          icon={faTimesCircle}
          color="darkgray"
        />
      </div>
    </div>
  )
}

export default ProposalCommandPreview
