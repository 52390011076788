import EtherscanLink from 'components/Collectives/Common/EtherscanLink'
import { TCollective } from 'components/Collectives/modals/AddProposalModal/AddProposal/ProposalCommand.types'
import { ERCAsset, ProposalCommandInput } from 'graphql/generated'
import { MemberType } from 'hooks/collectives/useProposalCommandPreview'
import { CollectiveUserSelectSingleValueShell } from 'components/Collectives/Common/SelectComponents/CollectiveUserSelectSingleValue'
import { CollectiveNFTSelectSingleValueShell } from 'components/Collectives/Common/SelectComponents/CollectiveAssetSelectSingleValue'

type UserPreviewProps = {
  user: Maybe<MemberType>
  collective: TCollective
  command?: Maybe<ProposalCommandInput>
  address: string
}

export const UserPreview: React.FC<UserPreviewProps> = ({
  user,
  collective,
  address
}) => {
  return user ? (
    <CollectiveUserSelectSingleValueShell user={user.user} />
  ) : (
    <EtherscanLink
      inline
      network={collective.network}
      type={'address'}
      address={address}
      shortAddress
    />
  )
}

type AssetPreviewProps = {
  asset: Maybe<ERCAsset>
  collective: TCollective
  contractAddress: string
}

export const AssetPreview: React.FC<AssetPreviewProps> = ({
  asset,
  collective,
  contractAddress
}) => {
  return asset ? (
    <CollectiveNFTSelectSingleValueShell
      tight={true}
      asset={asset}
      shortAddress
    />
  ) : (
    <EtherscanLink
      inline
      network={collective.network}
      type={'address'}
      address={contractAddress!}
      shortAddress
    />
  )
}

export const ProposalStepsContainer: React.FC<{
  buttons: React.ReactElement
  children?: React.ReactNode
}> = ({ children, buttons }) => {
  return (
    <div className="w-full flex-1 overflow-hidden flex flex-col">
      <div className="px-6 py-1 flex-1 overflow-auto flex flex-col">
        {children}
      </div>

      <div className="flex p-4 bg-gray-100 justify-end mt-4">{buttons}</div>
    </div>
  )
}
