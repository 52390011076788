import Bugsnag from 'lib/bugsnag'
import useToasts from 'hooks/useToasts'
import { useState } from 'react'
import {
  useExecProposalMutation,
  Collective,
  CollectiveProposalQueryResult,
  EstimatedExecutionGasQueryResult,
  EstimatedExecutionGasQueryVariables
} from 'graphql/generated'
import {
  getSafe,
  getSafeInstructionsFromCommands,
  parseWeb3ErrorMessage,
  TTxUiState
} from 'lib/collectives/helpers'
import { useWeb3 } from 'context/Web3Context'
import { useQueryErrorHandler } from 'hooks'
import { EthSafeSignature } from '@safe-global/protocol-kit/dist/src/utils/signatures/SafeSignature'
import { useLazyQuery } from 'lib/useLazyQuery'
import { EstimatedExecutionGasQuery } from 'graphql/documents'

export const BLACKHOLE = `0x0000000000000000000000000000000000000000`

type TCollective = Pick<Collective, 'safeAddress' | 'network'>
type TProposal = Pick<
  NonNullable<CollectiveProposalQueryResult['collectiveProposal']>,
  'id' | 'title' | 'commands' | 'signatures' | 'hasOnChainCommands'
>
interface IProps {
  collective: TCollective
  proposal: TProposal
}

export function useExecuteProposal({ collective, proposal }: IProps) {
  const { addToast } = useToasts()
  const { signer } = useWeb3()

  const [state, setState] = useState<Maybe<TTxUiState>>(null)

  const onExecError = useQueryErrorHandler(`Failed to execute proposal`)
  const [markProposalExecuted] = useExecProposalMutation({
    onError: onExecError
  })
  const estimateGas = useLazyQuery<
    EstimatedExecutionGasQueryResult,
    EstimatedExecutionGasQueryVariables
  >(EstimatedExecutionGasQuery)

  const executeProposal = async () => {
    try {
      if (!signer) {
        addToast('Missing wallet information', {
          appearance: 'warning'
        })
        return
      }

      if (!proposal.hasOnChainCommands) {
        await markProposalExecuted({
          variables: {
            proposalId: proposal.id,
            blockNumber: 0,
            txHash: BLACKHOLE
          }
        })

        setState(TTxUiState.TX_SUCCESS)
        return
      }

      const txPartials = getSafeInstructionsFromCommands(proposal.commands)

      if (!txPartials.length) {
        addToast('Missing execution instructions', {
          appearance: 'warning'
        })
        return
      }

      setState(TTxUiState.WAITING_FOR_PROVIDER)

      const safe = await getSafe(collective.safeAddress, signer)
      const tx = await safe.createTransaction({
        safeTransactionData: txPartials
      })

      proposal.signatures
        .filter(sig => sig.valid)
        .forEach(sig => {
          const ethSig = new EthSafeSignature(sig.user.address, sig.signature)
          tx.addSignature(ethSig)
        })

      const estimationResp = await estimateGas({ proposalId: proposal.id })
      const estimatedGas =
        estimationResp.data.collectiveProposal?.estimatedExecutionGas
      if (!estimatedGas) {
        throw new Error(
          `Failed to estimate gas. This transaction will likely fail, and is likely invalid.`
        )
      }

      setState(TTxUiState.WAITING_FOR_NETWORK)
      const execTx = await safe.executeTransaction(tx, {
        gasLimit: estimatedGas
      })
      await markProposalExecuted({
        variables: {
          proposalId: proposal.id,
          blockNumber: execTx.transactionResponse?.blockNumber || 0,
          txHash: execTx.transactionResponse?.hash || ''
        }
      })

      await execTx.transactionResponse?.wait()

      window.analytics?.trackAll(`Proposal - Execute - Complete`, {
        commandTypes: proposal.commands.map(x => x.__typename).join(' + '),
        proposalName: proposal.title,
        proposalId: proposal.id
      })
      setState(TTxUiState.TX_SUCCESS)
    } catch (err) {
      Bugsnag.notify(err)
      addToast(parseWeb3ErrorMessage(err), {
        appearance: 'warning'
      })
      setState(TTxUiState.TX_FAILURE)
      window.analytics?.trackAll(`Proposal - Execute - Error`, {
        commandTypes: proposal.commands.map(x => x.__typename).join(' + '),
        proposalName: proposal.title,
        proposalId: proposal.id
      })
    }
  }

  return { executeProposal, state }
}
