import cls from 'classnames'
import { RadioGroup } from '@headlessui/react'
import { ProposalCommandType, VotingAnonymity } from 'graphql/generated'
import { IProposalCommandInputProps } from './ProposalCommand.types'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCircle } from '@fortawesome/pro-regular-svg-icons'

const getStyles = (checked: boolean, active: boolean) =>
  cls(
    checked ? 'border-transparent' : 'border-gray-300',
    active ? 'border-blue-500 ring-2 ring-blue-500' : '',
    'relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none'
  )

export const AnonymityKeyMap = {
  [VotingAnonymity.TRANSPARENT]: 'Fully Transparent',
  [VotingAnonymity.ANONYMOUS]: 'Anonymous',
  [VotingAnonymity.PARTICIPATION]: 'Participation-Only'
}

export const AnonymityDescriptionMap = {
  [VotingAnonymity.TRANSPARENT]:
    'Proposal votes will be fully transparent, and will list who voted, and how they voted.',
  [VotingAnonymity.ANONYMOUS]:
    'Proposal votes will be completely anonymous. No one will be able to tell who voted, or how they voted.',
  [VotingAnonymity.PARTICIPATION]:
    'Proposal will display who voted, but not how they voted.'
}

function ChangeVotingAnonymityCommand({
  data,
  onChange,
  onValidityChange,
  collective
}: IProposalCommandInputProps) {
  const [votingAnonymity, setVotingAnonymity] = useState<VotingAnonymity>(
    data.votingAnonymity || VotingAnonymity.TRANSPARENT
  )

  useEffect(() => {
    onChange({
      type: ProposalCommandType.CHANGE_VOTING_ANONYMITY,
      votingAnonymity
    })
    // eslint-disable-next-line
  }, [votingAnonymity])

  const isValid = votingAnonymity !== collective.parameters.votingAnonymity
  useEffect(() => {
    onValidityChange(isValid)
    // eslint-disable-next-line
  }, [isValid])

  return (
    <div className="pt-2">
      <RadioGroup value={votingAnonymity} onChange={setVotingAnonymity}>
        <div className="space-y-4">
          <RadioGroup.Option
            value={VotingAnonymity.TRANSPARENT}
            className={({ checked, active }) => getStyles(checked, active)}
          >
            {({ active, checked }) => (
              <>
                <div className="flex items-center space-x-4">
                  <FontAwesomeIcon
                    icon={checked ? faCheckCircle : faCircle}
                    className={checked ? 'text-blue' : 'text-gray-500'}
                  />

                  <div className="text-sm">
                    <RadioGroup.Label
                      as="p"
                      className="font-medium text-gray-800"
                    >
                      {AnonymityKeyMap[VotingAnonymity.TRANSPARENT]}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as={'div'}
                      className="text-gray-500 overflow-hidden"
                    >
                      <p className="mt-2 font-thin">
                        {AnonymityDescriptionMap.TRANSPARENT}
                      </p>
                    </RadioGroup.Description>
                  </div>
                </div>

                <div
                  className={cls(
                    active ? 'border' : 'border-2',
                    checked ? 'border-blue-500' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>

          <RadioGroup.Option
            value={VotingAnonymity.PARTICIPATION}
            className={({ checked, active }) => getStyles(checked, active)}
          >
            {({ active, checked }) => (
              <>
                <div className="flex items-center space-x-4">
                  <FontAwesomeIcon
                    icon={checked ? faCheckCircle : faCircle}
                    className={checked ? 'text-blue' : 'text-gray-500'}
                  />

                  <div className="text-sm">
                    <RadioGroup.Label
                      as="p"
                      className="font-medium text-gray-800"
                    >
                      {AnonymityKeyMap[VotingAnonymity.PARTICIPATION]}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as={'div'}
                      className="text-gray-500 overflow-hidden"
                    >
                      <p className="mt-2 font-thin">
                        {AnonymityDescriptionMap.PARTICIPATION}
                      </p>
                    </RadioGroup.Description>
                  </div>
                </div>

                <div
                  className={cls(
                    active ? 'border' : 'border-2',
                    checked ? 'border-blue-500' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>

          <RadioGroup.Option
            value={VotingAnonymity.ANONYMOUS}
            className={({ checked, active }) => getStyles(checked, active)}
          >
            {({ active, checked }) => (
              <>
                <div className="flex items-center space-x-4">
                  <FontAwesomeIcon
                    icon={checked ? faCheckCircle : faCircle}
                    className={checked ? 'text-blue' : 'text-gray-500'}
                  />

                  <div className="text-sm">
                    <RadioGroup.Label
                      as="p"
                      className="font-medium text-gray-800"
                    >
                      {AnonymityKeyMap[VotingAnonymity.ANONYMOUS]}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as={'div'}
                      className="text-gray-500 overflow-hidden"
                    >
                      <p className="mt-2 font-thin">
                        {AnonymityDescriptionMap.ANONYMOUS}
                      </p>
                    </RadioGroup.Description>
                  </div>
                </div>

                <div
                  className={cls(
                    active ? 'border' : 'border-2',
                    checked ? 'border-blue-500' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        </div>
      </RadioGroup>
    </div>
  )
}

export default ChangeVotingAnonymityCommand
