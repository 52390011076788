import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faCoins,
  faLink,
  faNetworkWired,
  faShield,
  faUnlink,
  faUser,
  faUsers,
  faVoteYea,
  faWallet
} from '@fortawesome/pro-regular-svg-icons'
import { faKey, faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons'
import {
  CollectiveQueryResult,
  CollectiveTokenType,
  ProposalCommandInput,
  ProposalCommandType
} from 'graphql/generated'
import { isFunction, startCase, toLower } from 'lodash'

export type TCollective = Pick<
  NonNullable<CollectiveQueryResult['collective']>,
  | 'id'
  | 'name'
  | 'treasuryBalance'
  | 'parameters'
  | 'tokens'
  | 'safeThreshold'
  | 'parameters'
  | 'governanceType'
  | 'network'
  | 'totalMembers'
>

export interface IProposalCommandInputProps {
  disabled?: boolean
  data: ProposalCommandInput
  collective: TCollective
  onChange: CallbackWithParam<ProposalCommandInput>
  onValidityChange: CallbackWithParam<boolean>
}

type CommandLabelAndDescriptionFunction =
  | string
  | ((collective: TCollective, currentValue?: boolean) => string)

type TBlockUser = boolean
type TErrorMessage = string
type TDuplicateDataError = [TErrorMessage, TBlockUser]

export interface ICommandOption {
  component: TCommandComponent
  renderForTypes: ProposalCommandType[]
  label: CommandLabelAndDescriptionFunction
  description: CommandLabelAndDescriptionFunction
  icon: IconProp
  modifierIcon?: IconProp
  availableFor: CollectiveTokenType[]
  isDisabled?: boolean
  category: CategoryType
  disableSelectIfOtherExistingCommandTypes?: ProposalCommandType[]
  disableAddCommandOnDuplicateDataCheck?: CallbackWithParams<
    ProposalCommandInput[],
    ProposalCommandInput,
    TDuplicateDataError | false
  >
  allowDuplicates?: boolean
  beta?: boolean
}

export type TCommandComponent =
  | 'SendEtherCommand'
  | 'SendTokensCommand'
  | 'TransferAssetCommand'
  | 'ChangeExchangeRateCommand'
  | 'ChangeEnableWithdrawsCommand'
  | 'ChangeEnableDepositsCommand'
  | 'ChangeGateDepositsCommand'
  | 'AddMemberCommand'
  | 'RemoveMemberCommand'
  | 'RemoveZeroBalanceMemberCommand'
  | 'ChangeEnableNewMembershipRequests'
  | 'AddSignatorCommand'
  | 'RemoveSignatorCommand'
  | 'ChangeSafeThresholdCommand'
  | 'ChangeQuorumCommand'
  | 'CallRemoteCommand'
  | 'EnableNewMembersRequest'
  | 'LinkWalletCommand'
  | 'UnlinkWalletCommand'
  | 'ChangeMinEthContributionCommand'
  | 'SendEtherDisbursementCommand'
  | 'ChangeRequireVoteSignature'
  | 'ChangeVotingAnonymity'
  | 'ChangeDisableTokenTransfersCommand'

export enum CategoryType {
  Monetary,
  Members,
  CollectiveSettings,
  Signators,
  Wallet
}

export const COMMAND_OPTIONS: ICommandOption[] = [
  {
    component: 'SendEtherCommand',
    category: CategoryType.Monetary,
    renderForTypes: [ProposalCommandType.SEND_ETHER],
    label: 'Send ETH',
    description: `Send ETH from DAO's wallet to a specified wallet address`,
    icon: faCoins,
    availableFor: [CollectiveTokenType.OWNED, CollectiveTokenType.EXTERNAL],
    disableAddCommandOnDuplicateDataCheck: (commands, data) =>
      commands.some(
        command =>
          command.type === ProposalCommandType.SEND_ETHER &&
          command.target === data.target
      )
        ? ['Eth is already being sent to this address', true]
        : false
  },
  {
    component: 'SendEtherDisbursementCommand',
    category: CategoryType.Monetary,
    renderForTypes: [ProposalCommandType.SEND_DISBURSEMENT],
    disableSelectIfOtherExistingCommandTypes: [
      ProposalCommandType.SEND_DISBURSEMENT
    ],
    label: 'Disburse ETH',
    description: `Distribute ETH from the DAO's wallet to all token holders proportional to their ownership`,
    icon: faCoins,
    availableFor: [CollectiveTokenType.OWNED, CollectiveTokenType.EXTERNAL]
  },
  {
    component: 'SendTokensCommand',
    category: CategoryType.Monetary,
    renderForTypes: [ProposalCommandType.SEND_TOKEN],
    label: 'Mint Tokens',
    description: `Mint new DAO tokens, and send them to a given recipient`,
    icon: faCoins,
    availableFor: [CollectiveTokenType.OWNED],
    disableAddCommandOnDuplicateDataCheck: (commands, data) =>
      commands.some(
        command =>
          command.type === ProposalCommandType.SEND_TOKEN &&
          command.target === data.target
      )
        ? ['Tokens are already being sent to this address', true]
        : false
  },
  {
    component: 'TransferAssetCommand',
    category: CategoryType.Monetary,
    renderForTypes: [
      ProposalCommandType.TRANSFER_TOKENS,
      ProposalCommandType.TRANSFER_NFT,
      ProposalCommandType.TRANSFER_ERC721,
      ProposalCommandType.TRANSFER_ERC1155
    ],
    label: 'Transfer Assets',
    description: `Send an Asset (Tokens, NFTs) from the DAO's wallet to a given recipient`,
    icon: faCoins,
    availableFor: [CollectiveTokenType.OWNED, CollectiveTokenType.EXTERNAL],
    disableAddCommandOnDuplicateDataCheck: (commands, data) => {
      if (data.type === ProposalCommandType.TRANSFER_TOKENS) {
        return commands.some(
          command =>
            command.target === data.target &&
            command.contractAddress === data.contractAddress &&
            command.tokenId === data.tokenId
        )
          ? [`Tokens are already being sent to this address`, false]
          : false
      }
      const tokenCommandsAlreadyAdded = commands.some(
        command =>
          command.contractAddress === data.contractAddress &&
          command.tokenId === data.tokenId
      )
      return tokenCommandsAlreadyAdded
        ? [`Asset is already being sent to this address`, false]
        : false
    }
  },
  {
    component: 'ChangeExchangeRateCommand',
    category: CategoryType.CollectiveSettings,
    renderForTypes: [ProposalCommandType.CHANGE_EXCHANGE_RATE],
    disableSelectIfOtherExistingCommandTypes: [
      ProposalCommandType.CHANGE_EXCHANGE_RATE
    ],
    label: 'Change Exchange Rate',
    description: `Set the exchange value between ETH and your DAO's tokens`,
    icon: faCoins,
    availableFor: [CollectiveTokenType.OWNED]
  },
  {
    component: 'ChangeEnableWithdrawsCommand',
    category: CategoryType.CollectiveSettings,
    renderForTypes: [ProposalCommandType.CHANGE_ENABLE_WITHDRAWS],
    disableSelectIfOtherExistingCommandTypes: [
      ProposalCommandType.CHANGE_ENABLE_WITHDRAWS
    ],
    label: (collective, currentValue) =>
      currentValue ?? collective.parameters.enableWithdraws
        ? 'Disable Withdrawals'
        : 'Enable Withdrawals',
    description: (collective, currentValue) =>
      currentValue ?? collective.parameters.enableWithdraws
        ? `Disable DAO members to remove ETH from the DAO wallet`
        : `Enable DAO members to remove ETH from the DAO wallet`,
    icon: faCoins,
    availableFor: [CollectiveTokenType.OWNED]
  },
  {
    component: 'ChangeQuorumCommand',
    category: CategoryType.CollectiveSettings,
    renderForTypes: [ProposalCommandType.CHANGE_QUORUM],
    disableSelectIfOtherExistingCommandTypes: [
      ProposalCommandType.CHANGE_QUORUM
    ],
    label: 'Change Quorum',
    description: `Change the percentage of members needed to meet quorum`,
    icon: faVoteYea,
    availableFor: [CollectiveTokenType.OWNED, CollectiveTokenType.EXTERNAL]
  },
  {
    component: 'ChangeRequireVoteSignature',
    category: CategoryType.CollectiveSettings,
    renderForTypes: [ProposalCommandType.CHANGE_REQUIRE_VOTE_SIGNATURE],
    disableSelectIfOtherExistingCommandTypes: [
      ProposalCommandType.CHANGE_REQUIRE_VOTE_SIGNATURE
    ],
    label: (collective, currentValue) =>
      currentValue ?? collective.parameters.requireVotingSignature
        ? `Remove Signature Requirement to Vote`
        : `Require Signatures to Vote`,
    description: (collective, currentValue) =>
      currentValue ?? collective.parameters.requireVotingSignature
        ? `By disabling this setting, DAO members will be able to vote on their phone and desktop without having to enter their wallet. Signatures will not be verified each time someone votes and will be removed from the proposal screen.`
        : `By enabling this setting, DAO members will not be able to vote on their phone and will need to access their wallet each time they want to vote. Additionally, signatures will be verified each time someone votes.`,
    icon: faVoteYea,
    availableFor: [CollectiveTokenType.OWNED, CollectiveTokenType.EXTERNAL]
  },
  {
    component: 'ChangeVotingAnonymity',
    category: CategoryType.CollectiveSettings,
    renderForTypes: [ProposalCommandType.CHANGE_VOTING_ANONYMITY],
    label: `Change Voting Privacy`,
    description: `Change voting privacy setting`,
    icon: faShield,
    availableFor: [CollectiveTokenType.OWNED, CollectiveTokenType.EXTERNAL]
  },
  {
    component: 'ChangeGateDepositsCommand',
    category: CategoryType.Members,
    renderForTypes: [ProposalCommandType.CHANGE_GATE_DEPOSITS],
    disableSelectIfOtherExistingCommandTypes: [
      ProposalCommandType.CHANGE_GATE_DEPOSITS
    ],
    label: (collective, currentValue) =>
      currentValue ?? collective.parameters.gateDeposits
        ? 'Allow Anyone To Join'
        : 'Require Membership Approval',
    description: (collective, currentValue) =>
      currentValue ?? collective.parameters.gateDeposits
        ? `Allow anyone with ${collective.tokens[0].symbol} tokens to join the DAO.`
        : `Members must be approved in order to contribute.`,
    icon: faCoins,
    availableFor: [CollectiveTokenType.OWNED]
  },
  {
    component: 'ChangeEnableDepositsCommand',
    category: CategoryType.CollectiveSettings,
    renderForTypes: [ProposalCommandType.CHANGE_ENABLE_DEPOSITS],
    disableSelectIfOtherExistingCommandTypes: [
      ProposalCommandType.CHANGE_ENABLE_DEPOSITS
    ],
    label: (collective, currentValue) =>
      currentValue ?? collective.parameters.enableDeposits
        ? 'Disable Contributions (ETH)'
        : 'Enable Contributions (ETH)',
    description: (collective, currentValue) =>
      currentValue ?? collective.parameters.enableDeposits
        ? `The DAO's wallet will no longer accept contributions`
        : `The DAO's wallet will accept contributions`,
    icon: faCoins,
    availableFor: [CollectiveTokenType.OWNED]
  },
  {
    component: 'AddMemberCommand',
    category: CategoryType.Members,
    renderForTypes: [ProposalCommandType.ADD_MEMBER],
    label: 'Add Member',
    description:
      'Add a new member to the DAO (specified by their wallet address)',
    icon: faUser,
    modifierIcon: faPlus,
    availableFor: [CollectiveTokenType.OWNED],
    disableAddCommandOnDuplicateDataCheck: (commands, data) =>
      commands.some(
        command =>
          command.type === ProposalCommandType.ADD_MEMBER &&
          command.memberAddress === data.memberAddress
      )
        ? ['Member with this address is already being added', true]
        : false
  },
  {
    component: 'RemoveMemberCommand',
    category: CategoryType.Members,
    renderForTypes: [ProposalCommandType.REMOVE_MEMBER],
    disableSelectIfOtherExistingCommandTypes: [
      ProposalCommandType.REMOVE_ZERO_BALANCE_MEMBERS
    ],
    label: 'Remove Member',
    description:
      'Remove an existing DAO member (specified by their wallet address)',
    icon: faUser,
    modifierIcon: faTimes,
    availableFor: [CollectiveTokenType.OWNED],
    disableAddCommandOnDuplicateDataCheck: (commands, data) =>
      commands.some(
        command =>
          command.type === ProposalCommandType.REMOVE_MEMBER &&
          command.memberAddress === data.memberAddress
      )
        ? ['Member with this address is already being removed', true]
        : false
  },
  {
    component: 'ChangeEnableNewMembershipRequests',
    category: CategoryType.Members,
    renderForTypes: [ProposalCommandType.CHANGE_ENABLE_NEW_MEMBERSHIP_REQUESTS],
    disableSelectIfOtherExistingCommandTypes: [
      ProposalCommandType.CHANGE_ENABLE_NEW_MEMBERSHIP_REQUESTS
    ],
    label: collective =>
      collective.parameters.enableNewMembershipRequests
        ? 'Disable Membership Requests'
        : 'Allow New Membership Requests',
    description: (collective, currentValue) =>
      currentValue ?? collective.parameters.enableNewMembershipRequests
        ? `${collective.name} DAO currently accepts new membership requests. This command will cause the DAO to not receive any further membership requests.`
        : `${collective.name} DAO does not currently accept new membership requests. This command will cause the DAO to allow receiving new membership requests.`,
    icon: faUsers,
    availableFor: [CollectiveTokenType.OWNED]
  },
  {
    component: 'RemoveZeroBalanceMemberCommand',
    category: CategoryType.Members,
    renderForTypes: [ProposalCommandType.REMOVE_ZERO_BALANCE_MEMBERS],
    disableSelectIfOtherExistingCommandTypes: [
      ProposalCommandType.REMOVE_ZERO_BALANCE_MEMBERS,
      ProposalCommandType.REMOVE_MEMBER
    ],
    label: 'Remove All Zero-Balance Members',
    description: collective =>
      `Remove all existing DAO members that have zero ${collective.tokens[0].symbol} token balance (specified by their wallet address)`,
    icon: faUsers,
    modifierIcon: faTimes,
    availableFor: [CollectiveTokenType.OWNED]
  },
  {
    component: 'AddSignatorCommand',
    category: CategoryType.Signators,
    renderForTypes: [ProposalCommandType.ADD_SIGNATOR],
    label: 'Designate Signator',
    description: `Designate an existing DAO member as a signator, granting them permission to execute proposals`,
    icon: faKey,
    modifierIcon: faPlus,
    availableFor: [CollectiveTokenType.OWNED, CollectiveTokenType.EXTERNAL],
    disableAddCommandOnDuplicateDataCheck: (commands, data) =>
      commands.some(
        command =>
          command.type === ProposalCommandType.ADD_SIGNATOR &&
          command.signatorAddress === data.signatorAddress
      )
        ? ['Signator with this address is already being added', true]
        : false
  },
  {
    component: 'RemoveSignatorCommand',
    category: CategoryType.Signators,
    renderForTypes: [ProposalCommandType.REMOVE_SIGNATOR],
    label: 'Revoke Signator',
    description: `Revoke an existing DAO signator's permission to execute proposals`,
    icon: faKey,
    modifierIcon: faTimes,
    availableFor: [CollectiveTokenType.OWNED, CollectiveTokenType.EXTERNAL],
    disableAddCommandOnDuplicateDataCheck: (commands, data) =>
      commands.some(
        command =>
          command.type === ProposalCommandType.REMOVE_SIGNATOR &&
          command.signatorAddress === data.signatorAddress
      )
        ? ['Signator with this address is already being removed', true]
        : false
  },
  {
    component: 'ChangeSafeThresholdCommand',
    category: CategoryType.CollectiveSettings,
    renderForTypes: [ProposalCommandType.CHANGE_SAFE_THRESHOLD],
    disableSelectIfOtherExistingCommandTypes: [
      ProposalCommandType.CHANGE_SAFE_THRESHOLD
    ],
    label: 'Change Minimum Signators',
    description: `Change the amount of signators needed to execute a proposal`,
    icon: faCoins,
    availableFor: [CollectiveTokenType.OWNED, CollectiveTokenType.EXTERNAL]
  },
  {
    component: 'ChangeMinEthContributionCommand',
    category: CategoryType.CollectiveSettings,
    renderForTypes: [ProposalCommandType.CHANGE_MIN_ETH_CONTRIBUTION],
    disableSelectIfOtherExistingCommandTypes: [
      ProposalCommandType.CHANGE_MIN_ETH_CONTRIBUTION
    ],
    label: 'Change Minimum ETH Contribution',
    description: `Adjust the minimum required amount of ETH to join the DAO`,
    icon: faCoins,
    availableFor: [CollectiveTokenType.OWNED]
  },
  {
    component: 'LinkWalletCommand',
    category: CategoryType.Wallet,
    renderForTypes: [ProposalCommandType.LINK_WALLET],
    label: 'Link Wallet',
    description: `Link and track an external wallet`,
    icon: faWallet,
    modifierIcon: faLink,
    availableFor: [CollectiveTokenType.OWNED, CollectiveTokenType.EXTERNAL],
    disableAddCommandOnDuplicateDataCheck: (commands, data) =>
      commands.some(
        command =>
          command.type === ProposalCommandType.LINK_WALLET &&
          command.target === data.target
      )
        ? ['Wallet with this address is already being linked', true]
        : false
  },
  {
    component: 'UnlinkWalletCommand',
    category: CategoryType.Wallet,
    renderForTypes: [ProposalCommandType.UNLINK_WALLET],
    label: 'Unlink Wallet',
    description: `Unlink and stop tracking an external wallet`,
    icon: faWallet,
    modifierIcon: faUnlink,
    availableFor: [CollectiveTokenType.OWNED, CollectiveTokenType.EXTERNAL],
    disableAddCommandOnDuplicateDataCheck: (commands, data) =>
      commands.some(
        command =>
          command.type === ProposalCommandType.UNLINK_WALLET &&
          command.walletId === data.walletId
      )
        ? ['Wallet with this address is already being unlinked', true]
        : false
  },
  {
    component: 'CallRemoteCommand',
    category: CategoryType.CollectiveSettings,
    renderForTypes: [ProposalCommandType.CALL_REMOTE],
    label: 'Call Remote Contract',
    description: `Call a remote contract method`,
    icon: faNetworkWired,
    availableFor: [CollectiveTokenType.OWNED, CollectiveTokenType.EXTERNAL]
  },
  {
    component: 'ChangeDisableTokenTransfersCommand',
    category: CategoryType.CollectiveSettings,
    renderForTypes: [ProposalCommandType.CHANGE_DISABLE_TOKEN_TRANSFERS],
    disableSelectIfOtherExistingCommandTypes: [
      ProposalCommandType.CHANGE_DISABLE_TOKEN_TRANSFERS
    ],
    label: 'Change Token Transferability',
    description: collective =>
      collective.parameters.disableTokenTransfers
        ? `Token transferability is currently disabled. Use this command to enable token transferability.`
        : `Token transferability is currently enabled. Use this command to disable token transferability.`,
    icon: faCoins,
    availableFor: [CollectiveTokenType.OWNED]
  }
]

export function getCommandOption(commandType: ProposalCommandType) {
  return COMMAND_OPTIONS.find(x => x.renderForTypes.includes(commandType))
}

export function getCommandsForCategory(
  governanceType: CollectiveTokenType,
  category: CategoryType
) {
  return COMMAND_OPTIONS.filter(
    option =>
      option.availableFor.includes(governanceType) &&
      option.category === category
  )
}

const CommandsByComponentMap = COMMAND_OPTIONS.reduce((total, current) => {
  total[current.component] = current
  return total
}, {} as Record<TCommandComponent, ICommandOption>)

export function getCommandLabelByComponent(
  commandType: TCommandComponent,
  collective: TCollective,
  currentValue?: boolean
) {
  const option = CommandsByComponentMap[commandType]
  if (!option) {
    return ''
  }

  if (isFunction(option.label)) {
    return option.label(collective, currentValue)
  }

  return option.label
}

export function getCommandDescription(
  commandType: ProposalCommandType,
  collective: TCollective,
  currentValue?: boolean
) {
  const option = getCommandOption(commandType)
  if (!option) {
    return ''
  }

  if (isFunction(option.description)) {
    return option.description(collective, currentValue)
  }

  return option.description
}

export function getCommandLabel(
  commandType: ProposalCommandType,
  collective: TCollective,
  currentValue?: boolean
) {
  const option = getCommandOption(commandType)
  if (!option) {
    return ''
  }

  if (isFunction(option.label)) {
    return option.label(collective, currentValue)
  }

  return option.label
}

function CleanupCommandTypeName(commandType: ProposalCommandType) {
  return startCase(toLower(commandType.replace(/_/g, ' ')))
}

const LABELS_BY_TYPE: Partial<{
  [key in ProposalCommandType]: string
}> = {
  ...COMMAND_OPTIONS.filter(
    option => !isFunction(option.label) && option.renderForTypes.length === 1
  ).map(option => ({ [option.renderForTypes[0]]: option.label })),

  // Custom labels where above dont qualify
  [ProposalCommandType.CHANGE_ENABLE_WITHDRAWS]: 'Allow/Disable Withdrawals',
  [ProposalCommandType.CHANGE_GATE_DEPOSITS]:
    'Allow Contributions from Members Only/Anyone',
  [ProposalCommandType.CHANGE_ENABLE_DEPOSITS]:
    'Allow/Forbid Members to Contribute ETH',
  [ProposalCommandType.CHANGE_ENABLE_NEW_MEMBERSHIP_REQUESTS]:
    'Allow/Disallow Membership Requests',
  [ProposalCommandType.CHANGE_REQUIRE_VOTE_SIGNATURE]:
    'Change Requires Signatures to Vote',
  [ProposalCommandType.TRANSFER_TOKENS]: 'Transfer Tokens',
  [ProposalCommandType.TRANSFER_NFT]: 'Transfer NFTs',
  [ProposalCommandType.TRANSFER_ERC721]: 'Transfer ERC721',
  [ProposalCommandType.TRANSFER_ERC1155]: 'Transfer ERC1155',
  [ProposalCommandType.CHANGE_DISABLE_TOKEN_TRANSFERS]:
    'Enable/Disable Token Transfers'
}

export function getDelegationLabel(commandType: ProposalCommandType) {
  if (LABELS_BY_TYPE?.[commandType]) {
    return LABELS_BY_TYPE[commandType]
  }

  const option = getCommandOption(commandType)
  if (!option) {
    return ''
  }
  const extra =
    option.renderForTypes.length > 1
      ? ' (' + CleanupCommandTypeName(commandType) + ')'
      : ''
  return `${option.label}${extra}`
}
