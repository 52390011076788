import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhotoVideo } from '@fortawesome/pro-regular-svg-icons'
import { Typography } from 'components/common'
import { components, OptionProps } from 'react-select'
import Thumbnail from 'components/Thumbnail'
import BigNumber from 'bignumber.js'
import EtherscanLink from '../EtherscanLink'
import { useCollectiveContext } from 'context/CollectiveContext'
import { ERCAsset } from 'graphql/generated'

export const CollectiveNFTSelectSingleValueShell: React.FC<{
  asset: ERCAsset
  shortAddress?: boolean
  tight?: boolean
}> = ({ asset, tight }) => {
  const { collective } = useCollectiveContext()
  const contractType =
    asset.contractType === 'ERC721' || asset.contractType === 'ERC1155'
      ? 'NFTAsset'
      : 'ERC20Asset'

  return (
    <div className="flex flex-row items-center">
      <div
        className={
          tight
            ? 'flex flex-row pr-[10px]'
            : 'min-w-[50px] w-[50px] flex flex-row justify-center items-center'
        }
      >
        {contractType === 'NFTAsset' && !!asset.mediaUrls[0] ? (
          <Thumbnail
            useImgTag
            src={asset.mediaUrls[0]}
            initials={asset.name?.charAt(0)}
            className={'h-8 w-8 rounded-full overflow-hidden'}
          />
        ) : contractType === 'ERC20Asset' && !!asset.thumb ? (
          <Thumbnail
            useImgTag
            src={asset.thumb}
            initials={asset.name?.charAt(0)}
            className={'h-8 w-8 rounded-full overflow-hidden'}
          />
        ) : (
          <FontAwesomeIcon
            icon={faPhotoVideo}
            className="h-12 w-12 rounded-full overflow-hidden"
          />
        )}
      </div>

      {contractType === 'NFTAsset' && (
        <div className="flex flex-col justify-center flex-1 overflow-hidden">
          <Typography className="text-[13px] font-normal">
            {asset.name} {asset.symbol !== '(n/a)' && `(${asset.symbol})`} /
            Token ID: {asset.tokenId}
          </Typography>

          <EtherscanLink
            network={collective.network}
            type={'nft'}
            address={asset.address}
            shortAddress
            tokenId={asset.tokenId}
          />
        </div>
      )}

      {contractType === 'ERC20Asset' && (
        <div className="flex flex-col justify-center flex-1 overflow-hidden">
          <Typography className="text-[13px] font-normal">
            {asset.name === asset.symbol
              ? `$${asset.symbol} (${new BigNumber(asset.balance)
                  .shiftedBy(-(asset.decimals || 0))
                  .toFormat(2)})`
              : `${asset.name} (${new BigNumber(asset.balance)
                  .shiftedBy(-(asset.decimals || 0))
                  .toFormat(2)} ${asset.symbol})`}
          </Typography>

          <EtherscanLink
            network={collective.network}
            type={'token'}
            address={asset.address}
            shortAddress
          />
        </div>
      )}
    </div>
  )
}

const CollectiveNFTSelectSingleValue = ({
  ...rest
}: OptionProps<ERCAsset, false, any>) => {
  const nft = rest.data

  return (
    <components.SingleValue {...rest}>
      <CollectiveNFTSelectSingleValueShell asset={nft} />
    </components.SingleValue>
  )
}

export default CollectiveNFTSelectSingleValue
