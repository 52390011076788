import React, { useState } from 'react'
import { CollectiveProposalCommand } from 'graphql/generated'
import { TCollective } from 'components/Collectives/modals/AddProposalModal/AddProposal/ProposalCommand.types'
import Typography from 'components/common/Typography'
import EtherscanLink from 'components/Collectives/Common/EtherscanLink'
import { formatToken } from 'lib/collectives/helpers'
import useProposalCommandPreview from 'hooks/collectives/useProposalCommandPreview'
import { UserPreview, AssetPreview } from './common'
import { AnonymityKeyMap } from 'components/Collectives/modals/AddProposalModal/AddProposal/ChangeVotingAnonymityCommand'
import { twMerge } from 'tailwind-merge'
import Button from 'components/Button'
import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons'

type ProposalCommandDetailsProps = {
  commandLabel: string
  collective: TCollective
  command: CollectiveProposalCommand
  limitTexts: boolean
  index: number
}

type SummaryPart = {
  label: string
  details: React.ReactNode | string
}
interface ICommandSummaryBox {
  commandLabel: string
  parts?: Maybe<SummaryPart[]>
  index: number
}

const loadAssetInfoForCommandTypes = [
  'CollectiveProposalTransferTokensCommand',
  'CollectiveProposalTransferERC1155Command',
  'CollectiveProposalTransferERC721Command',
  'CollectiveProposalTransferNFTCommand'
]

const ProposalCommandDetails: React.FC<ProposalCommandDetailsProps> = ({
  collective,
  command,
  commandLabel,
  index
}) => {
  const { asset, findMemberByAddress } = useProposalCommandPreview({
    collectiveId: collective.id,
    address: 'contractAddress' in command ? command.contractAddress : null,
    tokenId: 'tokenId' in command ? command.tokenId : null,
    skip: !loadAssetInfoForCommandTypes.includes(command.__typename)
  })

  const [showMore, setShowMore] = useState(false)

  switch (command.__typename) {
    case 'CollectiveProposalAddMemberCommand':
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'Member Address',
              details: (
                <EtherscanLink
                  inline
                  network={collective.network}
                  type={'address'}
                  address={command.memberAddress!}
                  shortAddress
                />
              )
            }
          ]}
        />
      )
    case 'CollectiveProposalRemoveMemberCommand': {
      const user = findMemberByAddress(command.memberAddress!)

      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'MEMBER',
              details: (
                <UserPreview
                  address={command.memberAddress!}
                  user={user}
                  collective={collective}
                />
              )
            }
          ]}
        />
      )
    }
    case 'CollectiveProposalChangeEnableNewMembershipRequests':
      return <CommandSummaryBox index={index} commandLabel={commandLabel} />
    case 'CollectiveProposalChangeEnableWithdrawsCommand':
      return <CommandSummaryBox index={index} commandLabel={commandLabel} />
    case 'CollectiveProposalChangeEnableDepositsCommand':
      return <CommandSummaryBox index={index} commandLabel={commandLabel} />
    case 'CollectiveProposalChangeGateDepositsCommand':
      return <CommandSummaryBox index={index} commandLabel={commandLabel} />
    case 'CollectiveProposalChangeRequireVotingSignatureCommand':
      return <CommandSummaryBox index={index} commandLabel={commandLabel} />
    case 'CollectiveProposalChangeVotingAnonymityCommand':
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'Voting Privacy',
              details: (
                <p className="text-sm text-gray-800 line-clamp-none font-bold mt-2">
                  {AnonymityKeyMap[command.votingAnonymity]}
                </p>
              )
            }
          ]}
        />
      )
    case 'CollectiveProposalChangeExchangeRateCommand':
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={`Set exchange rate to: ${command.exchangeRate} ${collective.tokens[0].symbol} = 1 Ether`}
        />
      )
    case 'CollectiveProposalSendEtherCommand': {
      const user = findMemberByAddress(command.target!)

      return (
        <CommandSummaryBox
          index={index}
          commandLabel={`Send Ether`}
          parts={[
            {
              label: 'AMNT',
              details: `${formatToken(command.value!)} ETH`
            },
            {
              label: 'TO',
              details: (
                <UserPreview
                  address={command.target!}
                  user={user}
                  collective={collective}
                />
              )
            }
          ]}
        />
      )
    }
    case 'CollectiveProposalSendEtherDisbursementCommands':
      return (
        <div>
          <CommandSummaryBox
            index={index}
            commandLabel={`Disburse ${formatToken(command.value!)} Ether to ${
              collective.totalMembers
            } members`}
          />

          <div className="px-2 pb-2 flex justify-end">
            <Button
              size="xxs"
              label={!showMore ? 'Show Amounts' : 'Hide Amounts'}
              color="textOnly"
              icon={!showMore ? faChevronRight : faChevronDown}
              onClick={() => setShowMore(!showMore)}
              iconPlacement="after"
            />
          </div>

          <div
            className={twMerge(
              'space-y-2 overflow-auto transition-all ease-in-out duration-300 rounded-md',
              showMore ? 'max-h-72 border border-gray-100 p-2' : 'px-2 h-0'
            )}
          >
            {command.commands.map((c, i) => (
              <Typography
                size="sm"
                key={`disburse_${i}`}
                className="w-full flex rounded-lg p-2 bg-gray-100"
              >
                <div className="mr-1">
                  Disburse {formatToken(c.value!).slice(0, 10)} ETH to
                </div>
                <EtherscanLink address={c.target} inline shortAddress />
              </Typography>
            ))}
          </div>
        </div>
      )
    case 'CollectiveProposalSendTokenCommand': {
      const contractDetails = collective.tokens[0]
      const user = findMemberByAddress(command.target)

      return (
        <CommandSummaryBox
          index={index}
          commandLabel={`Mint tokens`}
          parts={[
            {
              label: 'AMNT',
              details: (
                <EtherscanLink
                  inline
                  network={collective.network}
                  type={'address'}
                  address={collective.tokens[0].address}
                  label={`${formatToken(command.value!)} ${
                    contractDetails ? contractDetails.symbol : 'tokens'
                  }`}
                  shortAddress
                />
              )
            },
            {
              label: 'TO',
              details: (
                <UserPreview
                  address={command.target!}
                  user={user}
                  collective={collective}
                />
              )
            }
          ]}
        />
      )
    }
    case 'CollectiveProposalTransferTokensCommand': {
      const user = findMemberByAddress(command.target)

      return (
        <CommandSummaryBox
          index={index}
          commandLabel={`Transfer tokens `}
          parts={[
            {
              label: 'AMNT',
              details: (
                <EtherscanLink
                  inline
                  network={collective.network}
                  type={'address'}
                  address={command.contractAddress!}
                  label={`${formatToken(command.value!)} ${
                    asset ? asset.symbol : 'tokens'
                  }s`}
                  shortAddress={true}
                />
              )
            },
            {
              label: 'TO',
              details: (
                <UserPreview
                  address={command.target!}
                  user={user}
                  collective={collective}
                />
              )
            }
          ]}
        />
      )
    }
    case 'CollectiveProposalTransferERC1155Command':
    case 'CollectiveProposalTransferERC721Command':
    case 'CollectiveProposalTransferNFTCommand': {
      const user = findMemberByAddress(command.target)

      return (
        <CommandSummaryBox
          index={index}
          commandLabel={`Transfer Asset`}
          parts={[
            {
              label: 'ASSET',
              details: (
                <AssetPreview
                  contractAddress={command.contractAddress!}
                  asset={asset}
                  collective={collective}
                />
              )
            },
            {
              label: 'TO',
              details: (
                <UserPreview
                  address={command.target!}
                  user={user}
                  collective={collective}
                />
              )
            }
          ]}
        />
      )
    }
    case 'CollectiveProposalAddSignatorCommand': {
      const user = findMemberByAddress(command.signatorAddress!)

      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'MEMBER',
              details: (
                <UserPreview
                  address={command.signatorAddress!}
                  user={user}
                  collective={collective}
                />
              )
            }
          ]}
        />
      )
    }
    case 'CollectiveProposalRemoveSignatorCommand': {
      const user = findMemberByAddress(command.signatorAddress!)

      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'MEMBER',
              details: (
                <UserPreview
                  address={command.signatorAddress!}
                  user={user}
                  collective={collective}
                />
              )
            }
          ]}
        />
      )
    }
    case 'CollectiveProposalChangeSafeThresholdCommand':
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'AMNT',
              details: `${command.safeThreshold} will be required`
            }
          ]}
        />
      )
    case 'CollectiveProposalUpdateQuorumCommand':
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'AMNT',
              details: `${command.quorumNeededInPercentage}% will be required`
            }
          ]}
        />
      )
    case 'CollectiveProposalChangeMinEthContributionCommand':
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'AMNT',
              details: `${formatToken(
                command.minEthContribution!
              )} ETH will now be required`
            }
          ]}
        />
      )
    case 'CollectiveProposalCallRemoteCommand':
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'TARGET',
              details: <EtherscanLink address={command.target} shortAddress />
            },
            ...(command.value !== '0'
              ? [{ label: 'VALUE', details: command.value }]
              : []),
            {
              label: 'ABI DATA',
              details: (
                <Typography size="sm" className="min-w-[400px] break-all">
                  {command.data}
                </Typography>
              )
            }
          ]}
        />
      )
    case 'CollectiveProposalLinkWalletCommand':
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'ADDRESS',
              details: (
                <EtherscanLink
                  inline
                  network={collective.network}
                  type={'address'}
                  label={command.walletNickname!}
                  address={command.target!}
                  shortAddress
                />
              )
            }
          ]}
        />
      )

    case 'CollectiveProposalUnlinkWalletCommand':
      return (
        <CommandSummaryBox
          index={index}
          commandLabel={commandLabel}
          parts={[
            {
              label: 'ADDRESS',
              details: (
                <EtherscanLink
                  inline
                  network={collective.network}
                  type={'address'}
                  label={command.walletId!}
                  address={command.walletId!}
                  shortAddress
                />
              )
            }
          ]}
        />
      )

    case 'CollectiveProposalChangeDisableTokenTransfersCommand':
      return <CommandSummaryBox index={index} commandLabel={commandLabel} />

    default:
      return null
  }
}

export const CommandSummaryBox: React.FC<ICommandSummaryBox> = ({
  commandLabel,
  parts
}) => {
  return (
    <div className="w-full flex rounded-lg mb-4 bg-gray-100">
      <div className="shrink-0 p-4">
        <Typography color="lightGray" fontWeight="light" size="xs">
          CMD
        </Typography>
        <Typography className="mt-2" size="sm">
          {commandLabel}
        </Typography>
      </div>

      {!!parts?.length && (
        <div className="flex-1 overflow-auto flex border-l border-gray-300">
          {parts.map((part, index) => {
            return (
              <div
                key={part.label + index}
                className={twMerge(
                  'px-3.5 p-4',
                  index > 0 && 'border-l border-gray-300'
                )}
              >
                <Typography color="lightGray" fontWeight="light" size="xs">
                  {part.label}
                </Typography>
                {typeof part.details === 'string' ? (
                  <Typography className="mt-2" size="sm">
                    {part.details}
                  </Typography>
                ) : (
                  <div className="mt-2">{part.details}</div>
                )}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ProposalCommandDetails
