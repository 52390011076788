import React from 'react'
import {
  CollectiveProposalCommand,
  ProposalCommandInput,
  ProposalCommandType
} from 'graphql/generated'
import {
  COMMAND_OPTIONS,
  getCommandLabel,
  TCollective
} from 'components/Collectives/modals/AddProposalModal/AddProposal/ProposalCommand.types'
import ProposalCommandPreview from './ProposalCommandPreview'
import ProposalCommandDetails from './ProposalCommandDetails'

type TProps = {
  collective: TCollective
  commandsInput?: ProposalCommandInput[]
  commands?: CollectiveProposalCommand[]
}

const createLabelMap = (collective: TCollective) => {
  return COMMAND_OPTIONS.reduce((total, current) => {
    current.renderForTypes.forEach(element => {
      total[element] = getCommandLabel(element, collective)
    })

    return total
  }, {} as Record<ProposalCommandType, string>)
}
type CollectiveCommandTypenames = CollectiveProposalCommand['__typename']

const ProposalCommandTypeMap: Record<
  CollectiveCommandTypenames,
  Maybe<ProposalCommandType>
> = {
  CollectiveProposalAddMemberCommand: ProposalCommandType.ADD_MEMBER,
  CollectiveProposalAddSignatorCommand: ProposalCommandType.ADD_SIGNATOR,
  CollectiveProposalCallRemoteCommand: ProposalCommandType.CALL_REMOTE,
  CollectiveProposalChangeEnableDepositsCommand:
    ProposalCommandType.CHANGE_ENABLE_DEPOSITS,
  CollectiveProposalChangeEnableNewMembershipRequests:
    ProposalCommandType.CHANGE_ENABLE_NEW_MEMBERSHIP_REQUESTS,
  CollectiveProposalChangeEnableWithdrawsCommand:
    ProposalCommandType.CHANGE_ENABLE_WITHDRAWS,
  CollectiveProposalChangeExchangeRateCommand:
    ProposalCommandType.CHANGE_EXCHANGE_RATE,
  CollectiveProposalChangeGateDepositsCommand:
    ProposalCommandType.CHANGE_GATE_DEPOSITS,
  CollectiveProposalChangeMinEthContributionCommand:
    ProposalCommandType.CHANGE_MIN_ETH_CONTRIBUTION,
  CollectiveProposalUpdateQuorumCommand: ProposalCommandType.CHANGE_QUORUM,
  CollectiveProposalChangeSafeThresholdCommand:
    ProposalCommandType.CHANGE_SAFE_THRESHOLD,
  CollectiveProposalLinkWalletCommand: ProposalCommandType.LINK_WALLET,
  CollectiveProposalRemoveMemberCommand: ProposalCommandType.REMOVE_MEMBER,
  CollectiveProposalRemoveSignatorCommand: ProposalCommandType.REMOVE_SIGNATOR,
  CollectiveProposalSendEtherDisbursementCommand:
    ProposalCommandType.SEND_DISBURSEMENT,
  CollectiveProposalSendEtherCommand: ProposalCommandType.SEND_ETHER,
  CollectiveProposalSendTokenCommand: ProposalCommandType.SEND_TOKEN,
  CollectiveProposalTransferERC721Command: ProposalCommandType.TRANSFER_ERC721,
  CollectiveProposalTransferERC1155Command:
    ProposalCommandType.TRANSFER_ERC1155,
  CollectiveProposalTransferNFTCommand: ProposalCommandType.TRANSFER_NFT,
  CollectiveProposalTransferTokensCommand: ProposalCommandType.TRANSFER_TOKENS,
  CollectiveProposalUnlinkWalletCommand: ProposalCommandType.UNLINK_WALLET,
  CollectiveProposalChangeDisableTokenTransfersCommand:
    ProposalCommandType.CHANGE_DISABLE_TOKEN_TRANSFERS,
  CollectiveProposalSendEtherDisbursementCommands:
    ProposalCommandType.SEND_DISBURSEMENT,
  CollectiveProposalChangeRequireVotingSignatureCommand:
    ProposalCommandType.CHANGE_REQUIRE_VOTE_SIGNATURE,
  CollectiveProposalChangeVotingAnonymityCommand:
    ProposalCommandType.CHANGE_VOTING_ANONYMITY
}

const getLabelForCommand = (
  dataMap: Record<ProposalCommandType, string>,
  command: CollectiveProposalCommand
) => {
  const commandType = ProposalCommandTypeMap[command.__typename]

  return commandType ? dataMap[commandType] : 'Unknown Command'
}

function CommandSummaryTable({ collective, commandsInput, commands }: TProps) {
  const commandLabelMap = createLabelMap(collective)

  const createView = !!commandsInput?.length
  const detailedView = !!commands?.length

  const items = createView
    ? commandsInput.map((command, index) => (
        <ProposalCommandPreview
          collective={collective}
          command={command}
          commandLabel={commandLabelMap[command.type]}
          key={command.type + index}
          limitTexts={true}
          index={index}
        />
      ))
    : detailedView
    ? commands.map((command, index) => (
        <ProposalCommandDetails
          key={command.__typename + index}
          collective={collective}
          command={command}
          commandLabel={getLabelForCommand(commandLabelMap, command)}
          limitTexts={true}
          index={index}
        />
      ))
    : null

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{items}</>
}

export default CommandSummaryTable
