import { useQueryErrorHandler } from 'hooks'
// import { itemsPerPage } from 'lib/config'
import { useCollectiveProposalsQuery } from 'graphql/generated'
import { useCollectiveContext } from 'context/CollectiveContext'

export function useListCollectiveProposals(
  collectiveId: string,
  first = 5,
  searchString?: string
) {
  const { demoMode } = useCollectiveContext()
  const onError = useQueryErrorHandler(
    'Failed to load proposals. Please try again later.'
  )
  const { loading, data, networkStatus, fetchMore } =
    useCollectiveProposalsQuery({
      variables: {
        collectiveId,
        proposalConnection: {
          first
        },
        searchString: searchString?.trim()
      },
      skip: !!demoMode,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError
    })

  const proposals = data?.collective?.proposals
  const edges = data?.collective?.proposals.edges
  const pageInfo = data?.collective?.proposals.pageInfo
  const loadingMore = networkStatus === 3

  const loadMore = () => {
    if (!pageInfo || !pageInfo.hasNextPage) {
      return
    } else if (loading || loadingMore) {
      return
    }

    fetchMore({
      variables: {
        proposalConnection: {
          first,
          after: pageInfo.endCursor
        },
        searchString: searchString?.trim()
      }
    })
  }

  return { proposals, edges, pageInfo, loading, loadingMore, loadMore }
}
